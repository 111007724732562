<div class="agenx-modal">
  <header class="agenx-modal__header">
    <h4 class="agenx-modal__h4">{{data.type === 'sub' ? 'FIRMA SUSCRIPTOR' : 'FIRMA AFORADOR'}}</h4>
    <img (click)="closeModal()" class="agenx-modal__img" [src]="icon_x_src" alt="Close">
  </header>

  <div class="agenx-modal__container">
    @if (!url_file) {
      <canvas #canvas class="agenx-modal__canvas"
        [width]="widhtCanvas" [height]="heightCanvas"></canvas>
    } @else {
      <img [src]="url_file" alt="" [width]="widhtCanvas" [height]="heightCanvas">
    }

    @if (signatureNeeded) {
    <p class="error-message">Firma requerida</p>
    }

    <div class="agenx-signature">
      <agenx-button (click)="clearPad()" [buttonText]="'Limpiar'" class="middle"></agenx-button>
      @if (!url_file) {
        <agenx-input-img (fileChange)="loadSignature($event)" [control]="signature" [type]="'number'"
          [placeholder]="'Escoja una imagen con la firma...'"
          [idInput]="'signature'" [label]="'Cargar Firma'" class="width-input"></agenx-input-img>
      }
      <agenx-button (click)="savePad()" [typeButton]="'green'" [buttonText]="'Aceptar'" class="middle"></agenx-button>
    </div>

  </div>

</div>
