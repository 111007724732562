import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfflineService } from '../../../../services/offline.service';

@Component({
  selector: 'agenx-paginator',
  standalone: true,
  imports: [NgClass],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class AgenxPaginatorComponent {
  /**
   * Current page
   *
   * @type {number}
   * @memberof AgenxPaginatorComponent
   */
  @Input() currentPage: any = 0;

  /**
   * Event emitter to emit the selected page
   *
   * @memberof AgenxPaginatorComponent
   */
  @Output() returnedPage = new EventEmitter<number>();

  /**
   * Total pages
   *
   * @type {number}
   * @memberof AgenxPaginatorComponent
   */
  @Input() pages: any[] = [];

  icon_arrow_src = '';

  constructor(private offlineSrv: OfflineService) {}

  /**
   * Function to change the current page
   *
   * @param {number} page
   * @memberof AgenxPaginatorComponent
   */
  changePage(page: number) {
    this.currentPage = page;
    this.returnedPage.emit(page);
  }

  /**
   * Function to click on the arrow
   *
   * @param {('left' | 'right')} direction
   * @memberof AgenxPaginatorComponent
   */
  clickOnArrow(direction: 'left' | 'right') {
    if (direction === 'left' && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
    } else if (direction === 'right' && this.currentPage < this.pages.length) {
      this.currentPage = this.currentPage + 1;
    }
    this.returnedPage.emit(this.currentPage);
  }

  ngOnInit() {
    this.icon_arrow_src = this.offlineSrv.loadAssetInLocal('Icon_Arrow.svg');
  }
}
