<div class="agenx-card" [ngClass]="currentClasses">
  <header class="agenx-card__header">
    @if (title) {
      <h4 class="agenx-card__title"> {{ title }} </h4>
    }
    @if (button) {
      <agenx-circle-button (click)="onButtonClick()"
        [buttonCircleSrc]="icon_src"
        [buttonCircleTitle]="button" style="margin-left: 1rem;"></agenx-circle-button>
    }
    @if (line) {
      <span class="agenx-card__line"></span>
    }
  </header>
  <div [ngClass]="{'agenx-card__container': line}">
    <ng-content></ng-content>
  </div>
</div>
