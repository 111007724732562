import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService } from './account.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private url = `${environment.api_url}/aforo/app`;
  private onlineStatusSubject = new BehaviorSubject<boolean>(navigator.onLine);
  public onlineStatus$ = this.onlineStatusSubject.asObservable();

  constructor(private http: HttpClient, private accountSrv: AccountService) {
    this.updateOnlineStatus();
    window.addEventListener('online', () => {
      this.updateOnlineStatus();
    });
    window.addEventListener('offline', () => {
      this.updateOnlineStatus();
    });
  }

  loadAssetInLocal(icon:string): string {
    const storedSvg: any = localStorage.getItem(icon);
    const svgBlob = new Blob([storedSvg], { type: 'image/svg+xml' });
    return URL.createObjectURL(svgBlob);
  }

  loadFromLocal(name: string, default_value: null | [] = null) {
    return JSON.parse(localStorage.getItem(name)!) ?? default_value;
  }

  private updateOnlineStatus() {
    const isOnline = navigator.onLine;
    this.onlineStatusSubject.next(isOnline);
  }

  removeFromLocal(name: string): void {
    localStorage.removeItem(name);
  }

  saveAssetsInLocal(): void {
    const icons = [
      'Icon_Loading_Blue.svg',
      'Icon_Info.svg',
      'Icon_Warning.svg',
      'Icon_X.svg',
      'Icon_Info.svg',
      'Icon_Loading.svg',
      'Icon_comeback2.svg',
      'Icon_Add_Images.svg',
      'Icon_Arrow.svg',
      'header-icon-plus.svg',
      'Icon_Download.svg',
      'Icon_Resend.svg',
      'Icon_Delete.svg',
      'Icon_Edit.svg',
    ];
    icons.forEach(icon => {
      if (!localStorage.getItem(icon)) {
        fetch(`assets/icons/${icon}`)
          .then((response) => response.text())
          .then((svgContent) => {
            localStorage.setItem(icon, svgContent);
          })
          .catch((error) => console.error('Error al obtener el SVG:', error));
      }
    })
  }

  saveGaugingsPends(data: any): Observable<any> {
    let headers = this.accountSrv.headers;
    return this.http.post<any>(`${this.url}/gaugings/`, data, { headers });
  }

  saveToLocal(name: string, data: any) {
    localStorage.setItem(name, JSON.stringify(data));
  }
}
