import { Component, HostListener } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { AgenxAlertComponent } from '../../ds/atoms/alert/alert.component';
import { AgenxButtonComponent } from '../../ds/atoms/button/button.component';
import { AgenxCardComponent } from '../../ds/atoms/card/card.component';
import { AgenxInputComponent } from '../../ds/atoms/input/input.component';
import { AgenxPaginatorComponent } from '../../ds/atoms/paginator/paginator.component';
import { AgenxAlertRadioComponent } from '../../ds/moleculs/alert-radio/alert-radio.component';
import { AgenxTabComponent } from '../../ds/moleculs/tab/tab.component';
import { AgenxHeaderComponent } from '../../ds/moleculs/header/header.component';
import { AgenxCardSubComponent } from '../../ds/organism/card-sub/card-sub.component';
import { AgenxTotalsComponent } from '../../ds/templates/totals/totals.component';

import { AccountService } from '../../../services/account.service';
import { DashboardService } from '../../../services/dashboard.service';
import { GaugingService } from '../../../services/gauging.service';
import { SubscriberService } from '../../../services/subscriber.service';
import { ToastService } from '../../../services/toast.service';

import { Flume, Subscriber } from '../../../interfaces/person.interface';
import { CardContent, GaugingFull, Totals } from '../../../interfaces/gauging.interface';
import { Microroute } from '../../../interfaces/dashboard.interface';
import { AgenxSelectComponent } from "../../ds/atoms/select/select.component";
import { CardValueContent } from '../../ds/organism/card-sub/card-sub.interface';
import { OfflineService } from '../../../services/offline.service';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [
    AgenxHeaderComponent,
    RouterModule,
    ReactiveFormsModule,
    AgenxPaginatorComponent,
    AgenxCardSubComponent,
    AgenxTabComponent,
    AgenxCardComponent,
    AgenxInputComponent,
    AgenxTotalsComponent,
    AgenxButtonComponent,
    AgenxAlertComponent,
    AgenxAlertRadioComponent,
    AgenxSelectComponent,
  ],
})
export class DashboardComponent {
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    this.card_x_page = event.target.innerWidth > 1580 ? 8 : 6;
  }

  private subscriptions: Subscription[] = [];
  user: Flume | null = null;
  date_today = new Date();

  tabs: { name: string; active: boolean }[] = [
    { name: 'SUSCRIPTORES PARA AFORAR', active: true },
    { name: 'SUSCRIPTORES', active: false },
    { name: 'SUSCRIPTORES AFORADOS', active: false },
  ];

  search_input = new FormControl({ value: '', disabled: false });
  micros_select = new FormControl({ value: '', disabled: false });
  micros_values: Array<{ value: string; label: string }> = [];
  subs_search: Array<Subscriber> = [];
  subs_cards: Array<CardContent> = [];
  pages: Array<number> = [];
  not_gauging_subs: Array<Subscriber> = [];
  not_gauging_cards: Array<CardContent> = [];
  not_gauging_pages: Array<number> = [];
  gauging_pends: Array<GaugingFull> = [];
  all_gauging_today: Array<GaugingFull> = [];
  gauging_subs: Array<GaugingFull> = [];
  gauging_cards: Array<CardContent> = [];
  gauging_pages: Array<number> = [];
  cards_to_show: Array<CardContent> = [];
  pageSelected: Array<number> = [1, 1, 1];
  newPage: boolean = true;
  card_x_page = 8;
  tab_index = 0;
  gaugings_today: Array<GaugingFull> = [];
  toast: any = null;
  tabSelected: string = this.tabs[0].name;
  loading: 'loading' | 'normal' = 'normal';
  loading_not_gau = false;
  loading_gau = false;
  btn_restart = '';
  micro_name = 'SUSCRIPTORES PARA AFORAR';
  totals_permanent: string = '0 m³ / 0 kg';
  totals_extra: string = '0 m³ / 0 kg';
  totals_ordinary: string = '0 m³ / 0 kg';
  totals_usable: string = '0 m³ / 0 kg';
  totals_unusable: string = '0 m³ / 0 kg';
  totals_organic: string = '0 m³ / 0 kg';
  totals_gauged: string = '0 m³ / 0 kg';
  totals_month: string = '0 m³ / 0 kg';
  totals_today: string = '0 m³ / 0 kg';
  gauged_sub: string = '';
  today_sub: string = '';
  month_subs: string = '';
  show_totals: boolean = false;
  no_internet = false;
  created_pends = false;
  message_pend = '';
  message_pend2 = '';

  constructor(
    private accountSrv: AccountService,
    private dashboardSrv: DashboardService,
    private gaugingSrv: GaugingService,
    private offlineSrv: OfflineService,
    private subscriberSrv: SubscriberService,
    private toastSrv: ToastService,
    private router: Router
  ) {}

  /**
   * Navigates to the create or edit page based on the card information.
   * @param {any} card_info - The card information.
   */
  goToCreateEdit(card_info: any): void {
    if (card_info.cardSubButtonType === 'Create') {
      this.router.navigateByUrl('/create-edit');
      this.offlineSrv.saveToLocal('subs_data', card_info.card_info);
      // this.gaugingSrv.gaugingData = null;
    } else {
      this.onClickSubCard(card_info);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subsciption) => subsciption.unsubscribe());
  }

  ngOnInit(): void {
    this.offlineSrv.onlineStatus$.subscribe((isOnline: boolean) => {
      this.no_internet = !isOnline;
      !this.no_internet &&
        this.gauging_pends.length > 0 &&
        this.sendGaugingsPends();
    });
    this.gauging_pends = this.offlineSrv.loadFromLocal('gauging_pends', []);
    this.user = this.accountSrv.userValue;

    if (this.no_internet) {
      this.gauging_pends.length > 1 ?
        this.message_pend = `se tienen ${this.gauging_pends.length} aforos` :
        this.message_pend = 'se tiene 1 aforo';
      this.onGetTotals(this.offlineSrv.loadFromLocal('totals'));
      this.all_gauging_today = this.gauging_pends.concat(
        this.offlineSrv.loadFromLocal('gauging_today', [])
      );
      this.onGetTodayGauging(this.all_gauging_today);
      this.onGetMicroroutes(this.offlineSrv.loadFromLocal('microroutes', []));
    } else {
      this.getTotals();
      this.getTodayGauging();
      this.getMicroroutes();
      this.gauging_pends.length > 0 && this.sendGaugingsPends();
    }

    this.card_x_page = window.innerWidth > 1580 ? 8 : 6;
  }

  /**
   * Handles the click event on a subscriber card.
   * @param {*} evt - The event containing the card information.
   */
  onClickSubCard(evt: any): void {
    console.log(evt);
    if (evt.cardSubButtonType === 'Create') {
      this.goToCreateEdit(evt);
    } else if (evt.cardSubButtonType === 'Download') {
      this.toast = this.toastSrv.load('Descargando reporte');
      this.gaugingSrv
        .downloadGaugingReport(evt.card_info.gauging_id)
        .subscribe({
          next: (result: any) => {
            this.toast.close();
            window.open(result.url, '_blank');
          },
          error: (error: { error: { detailed: string } }) => {
            let message = error.error.hasOwnProperty('detailed')
              ? error.error.detailed
              : 'No se pudo descargar el reporte.';
            this.no_internet &&
              (message += ' No cuenta con una conexión estable a internet.');
            this.toastSrv.error(message);
          },
        });
    } else if (evt.cardSubButtonType === 'Resend') {
      this.toast = this.toastSrv.load('Enviando reporte');
      this.gaugingSrv.resendGaugingReport(evt.card_info.gauging_id).subscribe({
        next: (result: any) => {
          this.toast.close();
          this.toastSrv.success('Se ha enviado el reporte con exito');
        },
        error: (error: { error: { detailed: string } }) => {
          let message = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo reenviar el reporte.';
          this.no_internet &&
            (message += ' No cuenta con una conexión estable a internet.');
          this.toastSrv.error(message);
        },
      });
    }
  }

  private onGetTotals(totals: Totals): void {
    if (totals) {
      this.show_totals = true;
      let m3_totals =
        totals.months_totals[1].m3 +
        totals.months_totals[2].m3 +
        totals.months_totals[3].m3;
      let kgrs_totals =
        totals.months_totals[1].kgrs +
        totals.months_totals[2].kgrs +
        totals.months_totals[3].kgrs;
      this.totals_gauged = `${m3_totals} m³ / ${kgrs_totals} kg`;
      this.totals_month = `${totals.months_totals[0].m3} m³ / ${totals.months_totals[0].kgrs} kg`;
      this.totals_today = `${totals.today_totals.m3} m³ / ${totals.today_totals.kgrs} kg`;
      this.totals_permanent = `${totals.today_totals.permanent_m3} m³ / ${totals.today_totals.permanent_kgrs} kg`;
      this.totals_extra = `${totals.today_totals.extra_m3} m³ / ${totals.today_totals.extra_kgrs} kg`;
      this.totals_ordinary = `${totals.today_totals.ordinary_m3} m³ / ${totals.today_totals.ordinary_kgrs} kg`;
      this.totals_usable = `${totals.today_totals.usable_m3} m³ / ${totals.today_totals.usable_kgrs} kg`;
      this.totals_unusable = `${totals.today_totals.unusable_m3} m³ / ${totals.today_totals.unusable_kgrs} kg`;
      this.totals_organic = `${totals.today_totals.organic_m3} m³ / ${totals.today_totals.organic_kgrs} kg`;
      this.gauged_sub = totals.gauged_sub;
      this.month_subs = totals.months_subs[0];
      this.today_sub = totals.today_sub;
    }
  }

  private getTotals(): void {
    this.subscriptions.push(
      this.gaugingSrv.getTotals().subscribe({
        next: (totals: Totals) => {
          this.offlineSrv.saveToLocal('totals', totals);
          this.onGetTotals(totals);
        },
        error: (error: { error: { detailed: string } }) => {
          let message = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo obtener la información.';
          this.no_internet &&
            (message += ' No cuenta con una conexión estable a internet.');
          this.toastSrv.error(message);
        },
      })
    );
  }

  private onGetTodayGauging(result: GaugingFull[]): void {
    this.gauging_pages = [];
    this.gauging_cards = [];
    this.gauging_subs = result;
    this.cards_to_show = [];
    result.length > 0 && this.setCards('gauging');
    this.loading_gau = false;
    if (this.no_internet) {
      this.onSearchSubscribers(
        this.offlineSrv.loadFromLocal('subscribers_search', [])
      );
    } else {
      this.search_input.setValue(this.offlineSrv.loadFromLocal('search'));
      this.searchSubscribers();
    }
  }

  private getTodayGauging(): void {
    this.loading_gau = true;
    this.gauging_subs = this.offlineSrv.loadFromLocal('gauging', []);
    this.subscriptions.push(
      this.gaugingSrv.getTodayGauging().subscribe({
        next: (result: GaugingFull[]) => {
          this.offlineSrv.saveToLocal('gauging_today', result);
          this.onGetTodayGauging(result);
        },
        error: (error: { error: { detailed: string } }) => {
          let message = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo obtener la información.';
          this.no_internet &&
            (message += ' No cuenta con una conexión estable a internet.');
          this.toastSrv.error(message);
        },
      })
    );
  }

  private onGetMicroroutes(micros: Microroute[]): void {
    this.micros_values.push({ value: '---', label: '---' });
    this.micros_values.push({ value: 'all', label: 'Todas' });
    micros.forEach((micro) => {
      this.micros_values.push({
        value: micro.id.toString(),
        label: micro.name,
      });
    });
    let micro_saved = this.offlineSrv.loadFromLocal('microroute');
    micro_saved
      ? this.setMicroroutes(undefined, micro_saved)
      : this.micros_select.setValue('---');
  }

  private getMicroroutes(): void {
    this.subscriptions.push(
      this.dashboardSrv.getMicroroutes().subscribe({
        next: (micros: Microroute[]) => {
          this.offlineSrv.saveToLocal('microroutes', micros);
          this.onGetMicroroutes(micros);
        },
        error: (error: { error: { detailed: string } }) => {
          let message = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo obtener la información.';
          this.no_internet &&
            (message += ' No cuenta con una conexión estable a internet.');
          this.toastSrv.error(message);
        },
      })
    );
  }

  private sendGaugingsPends(): void {
       if (this.gauging_pends.length > 1) {
        this.message_pend2 = `Se crearon ${this.gauging_pends.length} aforos
          de manera satisfactoria, los cuales estaban pendientes por falta de conexión.`;
       } else {
        this.message_pend2 = `Se creó 1 aforo de manera satisfactoria,
         el cual estaba pendiente por falta de conexión.`;
       }
    this.gauging_pends.forEach((gau: any) => {
      const properties = ['subscriber', 'id', 'm3', 'kgrs', 'pend'];
      properties.forEach((prop) => delete gau[prop]);
    });
    let data = { gaugings_list: this.gauging_pends };
    this.created_pends = false;
    this.subscriptions.push(
      this.offlineSrv.saveGaugingsPends(data).subscribe({
        next: (result: any) => {
          if (result.success.length > 0) {
            let aux = result.success.concat(
              this.offlineSrv.loadFromLocal('gauging_today', [])
            );
            this.onGetTodayGauging(aux);
            this.offlineSrv.removeFromLocal('gauging_pends');
            this.created_pends = true;
            this.offlineSrv.saveToLocal('gauging_today', aux);
          } else if (result.errors.length === 0) {
            let gauging_errors = '';
            result.errors.forEach((gauging: any, index: number) => {
              gauging_errors += `${gauging.subscriber_niud}`;
              index + 1 !== result.errors.length && (gauging_errors += ', ');
            });
            this.toastSrv.error(
              'No se pudieron crear los aforos de los sigueientes suscriptores(Niud): ' +
                gauging_errors
            );
          }
        },
        error: (error: { error: { detailed: string } }) => {
          let message = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo obtener la información.';
          this.no_internet &&
            (message += ' No cuenta con una conexión estable a internet.');
          this.toastSrv.error(message);
        },
      })
    );
  }

  /**
   * Sets the cards to be displayed based on the specified type.
   * @param { 'search' | 'not_gauging' | 'gauging' } type - The type of cards to set.
   */
  private setCards(type: 'search' | 'not_gauging' | 'gauging'): void {
    let values_content: Array<CardValueContent> = [];
    let array_gauging = this.gauging_subs;
    let array_subs =
      type === 'search' ? this.subs_search : this.not_gauging_subs;
    let array_cards =
      type === 'search'
        ? this.subs_cards
        : type === 'not_gauging'
        ? this.not_gauging_cards
        : this.gauging_cards;
    let array_pages =
      type === 'search'
        ? this.pages
        : type === 'not_gauging'
        ? this.not_gauging_pages
        : this.gauging_pages;
    let aux_content!: CardContent;

    if (type !== 'gauging') {
      array_subs.forEach((subsc, index) => {
        values_content = [
          { title: 'Niud:', value: subsc.niud },
          { title: 'Nombre:', value: subsc.name },
          { title: 'Dirección:', value: subsc.address },
          { title: 'Contrato:', value: subsc.contract },
          { title: 'Estrato:', value: `${subsc.stratum}` },
        ];
        aux_content = {
          valuesContent: values_content,
          id: subsc.id,
          email: subsc.email,
          stratum_id: subsc.stratum_id,
          identification: subsc.identification,
          gaugin_code: subsc.gaugin_code,
          gauging: subsc.gauging,
        };
        if (subsc.rate_type !== 'Aforo') {
          aux_content['alertInfo'] = {
            alertText: 'Este suscriptor no cuenta con tipo tarifa de aforo.',
            alertType: 'warning',
          };
        }
        array_cards.push(aux_content);
      });
    } else {
      array_gauging.forEach((gau, index) => {
        values_content = [
          { title: 'Niud:', value: gau.subscriber.niud },
          { title: 'Nombre:', value: gau.subscriber.name },
          { title: 'Dirección:', value: gau.subscriber.address },
          { title: 'M³ Volumen:', value: `${gau.m3}` },
          { title: 'Kg Totales:', value: `${gau.kgrs}` },
        ];
        aux_content = {
          valuesContent: values_content,
          id: gau.subscriber.id,
          gauging_id: gau.id,
          email: gau.subscriber.email,
          stratum_id: gau.subscriber.stratum_id,
          identification: gau.subscriber.identification,
          gaugin_code: gau.subscriber.gaugin_code,
          gauging: gau.subscriber.gauging,
        };
        if (gau.hasOwnProperty('pend')) {
          aux_content['alertInfo'] = {
            alertText: 'Aforo pendiente por crear, cuando haya conexión.',
            alertType: 'warning',
          };
        } else if (gau.subscriber.rate_type !== 'Aforo') {
          aux_content['alertInfo'] = {
            alertText: 'Este suscriptor no cuenta con tipo tarifa de aforo.',
            alertType: 'warning',
          };
        }
        array_cards.push(aux_content);
      });
    }

    let n_pages = array_cards.length / this.card_x_page;
    !Number.isInteger(n_pages) && (n_pages += 1);
    for (let index = 1; index <= n_pages; index++) {
      array_pages.push(index);
    }

    this.setPaginator(1);
    this.loading = 'normal';
    this.offlineSrv.saveToLocal('search', this.search_input.value!);
  }

  /**
   * Restart the tab 'SUSCRIPTORES PARA AFORAR'
   */
  restartMicroTab(): void {
    this.micro_name = 'SUSCRIPTORES PARA AFORAR';
    this.tabs[0].active = true;
    this.tabSelected = this.tabs[0].name;
    this.micros_select.setValue('---');
    this.not_gauging_pages = [];
    this.not_gauging_subs = [];
    this.cards_to_show = [];
    this.btn_restart = '';
    this.offlineSrv.saveToLocal('not_gauging', []);
  }

  private onSearchSubscribers(result: Array<Subscriber>): void {
    this.pages = [];
    this.cards_to_show = [];
    this.subs_cards = [];
    this.subs_search = result;
    this.subs_search.map((sub) => {
      this.gauging_subs.find((gau) => gau.subscriber.id === sub.id)
        ? (sub.gauging = true)
        : (sub.gauging = false);
      return sub;
    });
    result.length > 0 && this.setCards('search');
    this.loading = 'normal';
  }

  /**
   * Searches for subscribers based on the input value.
   */
  searchSubscribers(): void {
    let search = this.search_input.value;

    if (search) {
      this.loading = 'loading';
      this.subscriptions.push(
        this.subscriberSrv.getSubscribers(search).subscribe({
          next: (result: Subscriber[]) => {
            this.offlineSrv.saveToLocal('subscribers_search', result);
            this.onSearchSubscribers(result);
          },
          error: (error: { error: { detailed: string } }) => {
            let message = error.error.hasOwnProperty('detailed')
              ? error.error.detailed
              : 'No se pudo obtener los suscriptores.';
            this.no_internet &&
              (message += ' No cuenta con una conexión estable a internet.');
            this.toastSrv.error(message);
          },
        })
      );
    }
  }

  private onSetMicroroutes(
    result: Array<Subscriber>,
    select_micro: string,
    micro_selected: any
  ): void {
    if (select_micro) {
      this.micro_name = `MICRORRUTA ${micro_selected?.label}`.toUpperCase();
      this.tabs[0].active = true;
      this.tabSelected = this.tabs[0].name;
    }
    this.not_gauging_pages = [];
    this.not_gauging_cards = [];
    this.not_gauging_subs = result;
    result.length > 0 && this.setCards('not_gauging');
    this.loading_not_gau = false;
    this.setPaginator(1);
  }

  /**
   *  Get the subscribers for the microroute selected
   * @param select - input select HTML
   * @param value - microroute value to load
   */
  setMicroroutes(
    select?: HTMLSelectElement,
    value?: { value: string; label: string }
  ): void {
    let micro_selected = select
      ? this.micros_values[select.selectedIndex]
      : this.micros_values.find((micro) => micro.value === value?.value);

    if (micro_selected?.value !== '---') {
      let select_micro =
        micro_selected?.value !== 'all' ? micro_selected?.value : '';
      this.offlineSrv.saveToLocal('microroute', micro_selected);
      this.btn_restart = 'Seleccionar microrruta';
      this.cards_to_show = [];
      this.loading_not_gau = true;
      if (this.no_internet) {
        this.onSetMicroroutes(
          this.offlineSrv.loadFromLocal('not_gauging', []),
          select_micro!,
          micro_selected
        );
      } else {
        this.offlineSrv.saveToLocal('not_gauging', []);
        this.subscriptions.push(
          this.subscriberSrv.getSubscribersNotGauging(select_micro!).subscribe({
            next: (result: Subscriber[]) => {
              this.offlineSrv.saveToLocal('not_gauging', result);
              this.onSetMicroroutes(result, select_micro!, micro_selected);
            },
            error: (error: { error: { detailed: string } }) => {
              let message = error.error.hasOwnProperty('detailed')
                ? error.error.detailed
                : 'No se pudo obtener los suscriptores para aforar.';
              this.no_internet &&
                (message += ' No cuenta con una conexión estable a internet.');
              this.toastSrv.error(message);
            },
          })
        );
      }
    }
  }

  /**
   * Sets the paginator to the specified page.
   * @param {number} page - The page number to set.
   */
  setPaginator(page: any): void {
    let aux = this.not_gauging_cards;
    let from = 0;
    let to = 0;
    if (this.tabSelected === 'SUSCRIPTORES') {
      aux = this.subs_cards;
    }
    if (this.tabSelected === 'SUSCRIPTORES AFORADOS') {
      aux = this.gauging_cards;
    }
    to = page * this.card_x_page;
    from = to - this.card_x_page;

    this.cards_to_show = [];
    for (let index = from; index < to && index < aux.length; index++) {
      this.cards_to_show.push(aux[index]);
    }
    this.pageSelected[this.tab_index] = page;
  }

  /**
   * Changes the active tab based on the selected tab event.
   * @param {*} evt - The event containing the selected tab information.
   */
  setTabs(evt: any): void {
    this.tabSelected = evt.name;
    this.cards_to_show = [];
    this.setPaginator(1);
  }
}
