import { NgClass, NgStyle } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OfflineService } from '../../../../services/offline.service';

@Component({
  selector: 'agenx-alert',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AgenxAlertComponent implements OnInit {
  /**
   * The text to display in the alert
   *
   * @type {string}
   * @memberof AgenxAlertComponent
   */
  @Input() alertText: string = 'Text Alert';

  /**
   * the Padding right of the alert if is a radio alert
   *
   * @type {string}
   * @memberof AgenxAlertComponent
   */
  @Input() alertRadio: boolean = false;

  /**
   * Type of the alert
   *
   * @type {('info' | 'alert' | 'error' | 'radio')}
   * @memberof AgenxAlertComponent
   */
  @Input() alertType: 'info' | 'warning' | 'error' | 'radio' = 'info';

  /**
   * Type of the alert
   *
   * @type {('info' | 'alert' | 'error' | 'radio')}
   * @memberof AgenxAlertComponent
   */
  @Input() loading: boolean = false;

  /**
   * Icon URL of the alert
   *
   * @type {string}
   * @memberof AgenxAlertComponent
   */
  urlIcon: string = '';

  /**
   * Current classes of the button based on the typeButton, sizeButton and loadingButton
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxAlertComponent
   */
  currentClasses: Record<string, boolean> = {};

  no_internet = false;
  icon_loading_src = '';

  constructor(private offlineSrv: OfflineService) {}

  /**
   * Function to set the current classes of the button
   *
   * @memberof AgenxAlertComponent
   */
  setCurrentClasses() {
    this.currentClasses = {
      'agenx-alert--info': this.alertType === 'info',
      'agenx-alert--warning': this.alertType === 'warning',
      'agenx-alert--error': this.alertType === 'error',
      'agenx-alert--radio': this.alertRadio,
    };
  }

  /**
   * Function to set the url of the alert icon based on the type of the alert
   *
   * @return {*}
   * @memberof AgenxAlertComponent
   */
  setUrlAlert() {
    this.loading &&
      (this.icon_loading_src = this.offlineSrv.loadAssetInLocal(
        'Icon_Loading_Blue.svg'
      ));
    switch (this.alertType) {
      case 'info':
        return this.offlineSrv.loadAssetInLocal('Icon_Info.svg');
      case 'warning':
        return this.offlineSrv.loadAssetInLocal('Icon_Warning.svg');
      case 'error':
        return this.offlineSrv.loadAssetInLocal('Icon_X.svg');
      default:
        return this.offlineSrv.loadAssetInLocal('Icon_Info.svg');
    }
  }

  ngOnInit(): void {
    this.offlineSrv.onlineStatus$.subscribe((isOnline: boolean) => {
      this.no_internet = !isOnline;
    });
    this.setCurrentClasses();
    this.urlIcon = this.setUrlAlert();
  }
}
