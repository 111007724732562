import { Component, ElementRef, EventEmitter, HostListener, Inject, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { AgenxButtonComponent } from '../../atoms/button/button.component';
import { AgenxInputImgComponent } from '../../atoms/input-img/input-img.component';
import { FormControl } from '@angular/forms';
import { OfflineService } from '../../../../services/offline.service';

@Component({
  selector: 'agenx-signature',
  standalone: true,
  imports: [AgenxButtonComponent, MatDialogModule, AgenxInputImgComponent],
  templateUrl: './signature.component.html',
  styleUrl: './signature.component.scss',
})
export class AgenxSignatureComponent {
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  widhtCanvas = 450;
  heightCanvas = 250;
  signature = new FormControl();
  url_file: any = null;
  icon_x_src = '';

  @Output() sendSignature = new EventEmitter<string>();

  constructor(
    private offlineSrv : OfflineService,
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogRef: MatDialogRef<AgenxSignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getScreenSize();
  }

  /**
   * Adjusts the canvas size based on the window size.
   * @param {Event} [event] - The resize event.
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.heightCanvas = window.innerHeight * 0.6;
    this.widhtCanvas = window.innerWidth * 0.7;
  }

  /**
   * Closes the modal dialog and emits the signature image.
   */
  closeModal(): void {
    this.dialogRef.close({ signature: this.signatureImg });
  }

  /**
   * Initializes the signature pad after the view has been initialized.
   */
  ngAfterViewInit() {
    this.icon_x_src = this.offlineSrv.loadAssetInLocal('Icon_X.svg')
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  /**
   * Loads a signature from the provided file and displays it on the signature pad.
   * @param {File[]} files - The files containing the signature image.
   */
  loadSignature(files: File[]) {
    if (files[0]) {
      this.signaturePad.clear();
      const reader = new FileReader();
      reader.onload = (e) => {
        this.url_file = e.target?.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  /**
   * Clears the signature pad and resets the form control.
   */
  clearPad() {
    this.signaturePad.clear();
    this.url_file = null;
    this.signatureImg = '';
    this.signature.reset();
    setTimeout(
      () => (this.signaturePad = new SignaturePad(this.canvasEl.nativeElement))
    );
  }

  /**
   * Saves the current signature from the pad or loaded file and emits it.
   */
  savePad() {
    if (!this.url_file) {
      const base64Data = this.signaturePad.toDataURL();
      this.signatureImg = base64Data;
      this.signatureNeeded = this.signaturePad.isEmpty();
      if (!this.signatureNeeded) {
        this.signatureNeeded = false;
      }
    } else {
      this.signatureImg = this.url_file;
    }
    this.sendSignature.emit(this.signatureImg);
    this.closeModal();
  }
}
