import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { OfflineService } from '../../../../services/offline.service';

@Component({
  selector: 'agenx-input-img',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './input-img.component.html',
  styleUrl: './input-img.component.scss',
})
export class AgenxInputImgComponent {
  /**
   * Input placeholder
   *
   * @type {string}
   * @memberof AgenxInputImgComponent
   */
  @Input() placeholder: string = 'Input placeholder';

  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxInputImgComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxInputImgComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Input type
   *
   * @type {('text' | 'password' | 'number')}
   * @memberof AgenxInputImgComponent
   */
  @Input() type: 'text' | 'password' | 'number' = 'text';

  /**
   * Current classes of the input
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxInputImgComponent
   */
  labelClasses: Record<string, boolean> = {};

  /**
   * Input form control
   *
   * @type {FormControl}
   * @memberof AgenxInputImgComponent
   */
  @Input() control: FormControl = new FormControl();

  /**
   * Position of the label
   *
   * @type {('bottom' | 'top' )}
   * @memberof AgenxInputImgComponent
   */
  @Input() labelPosition: 'bottom' | 'top' = 'bottom';

  /**
   * Event to emit the value of the input
   *
   * @type {EventEmitter<string>}
   * @memberof AgenxInputImgComponent
   */
  @Input() load_files: Array<any> = [];
  @Output() fileChange = new EventEmitter<File[]>();
  @Output() fileDelete = new EventEmitter<File[]>();
  files_show: any = [];
  icon_src = '';
  icon_x_src = '';

  constructor(public offlineSrv: OfflineService) {}

  /**
   * Function to set the current classes of the input
   *
   * @memberof AgenxInputImgComponent
   */
  setLabelPosition() {
    this.labelClasses = {
      'agenx-input__label--top': this.labelPosition === 'top',
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.load_files.forEach((file) => {
      this.files_show.push({ id: file.id, name: file.name, url: file.file });
    });
  }

  ngOnInit() {
    this.icon_src = this.offlineSrv.loadAssetInLocal('Icon_Add_Images.svg');
    this.icon_x_src = this.offlineSrv.loadAssetInLocal('Icon_X.svg');
    this.setLabelPosition();
  }

  files: Array<any> = [];

  deleteFile(file: any): void {
    let text = `¿Esta seguro de eliminar la imagen ${file.name}?`;

    Swal.fire({
      title: 'Eliminar archivo',
      text,
      icon: 'warning',
      confirmButtonColor: '#e64544',
      cancelButtonColor: '#8b8b8c',
      confirmButtonText: 'Eliminar',
      showCancelButton: true,
    }).then((result: any) => {
      Swal.hideLoading();
      if (result.isConfirmed) {
        this.load_files.find((img) => img.id === file.id) &&
          this.fileDelete.emit(file);
        this.files_show = this.files_show.filter(
          (img: { id: any }) => img.id !== file.id
        );
        this.files = this.files.filter(
          (img: { id: any }) => img.id !== file.id
        );
        this.fileChange.emit(this.files);
      }
    });
  }

  uploadFile(evt: Event): void {
    const target = evt.target as HTMLInputElement;
    if (target && target.files) {
      for (let index = 0; index < target.files.length; index++) {
        const file = target.files[index];
        this.files.push(file);
        const fileUrl = URL.createObjectURL(file);
        this.files_show.push({ name: file.name, url: fileUrl });
      }
    }
    this.emitFileChange();
  }

  /**
   * Function to emit the value of the input
   *
   * @param {string} value
   * @memberof AgenxInputImgComponent
   */
  emitFileChange() {
    this.fileChange.emit(this.files);
  }
}
