<div class="agenx-input"
  [ngClass]="{'agenx-input--error': control.errors && control.touched, 'agenx-input--success': control.valid && control.touched}">
  <div class="agenx-input__input">
    <input [id]="idInput" [formControl]="control" class="agenx-input__input-file" type="file" multiple="multiple"
      accept=".png, .jpg, .jpeg" [placeholder]="placeholder" (change)="uploadFile($event)">
    <div class="agenx-input__files-list__container">
      @for ( file of files_show; track file.name; let i = $index) {
        <div class="agenx-input__files-list">
          <a target="_blank" [href]="file.url" class="agenx-input__files-name no-line">{{ file.name }}</a>
          <a class="delete-file" (click)="deleteFile(file)">
            <img alt="delete icon" [src]="icon_x_src" />
          </a>
        </div>
      }
    </div>
    <label [for]="idInput">
      <img class="agenx-input__input-img" alt="upload icon" [src]="icon_src" />
    </label>
  </div>
  <label [for]="idInput" class="agenx-input__label" [ngClass]="labelClasses"> {{ label }} </label>
</div>
