import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';

import { Flume } from '../interfaces/person.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private url = `${environment.api_url}/aforo/app`;
  private current_user: Flume | null = null;
  private userSubject = new BehaviorSubject<Flume | null>(null);

  constructor(
    private http: HttpClient,
  ) {
    this.checkSession();
  }

  login(data: any): Observable<boolean | undefined> {
    return this.http.post<Flume>(`${this.url}/account/login/`, data)
      .pipe(map((user: Flume) => {
        this.saveLocalStorage(user);
        return true;
      }));
  }

  get user$(): Observable<Flume | null> {
    return this.userSubject.asObservable();
  }

  get userValue(): Flume | null {
    return this.userSubject.getValue();
  }

  get headers(): { [key: string]: string } {
    let user = this.userValue as Flume;
    let headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      'EGC-AFORADOR': `${user.id}`,
    };

    user.company && (headers['EGC-EMPRESA-IDENTIFIER'] = user.company.toString());
    user.region && (headers['EGC-REGION-IDENTIFIER'] = user.region.toString());

    return headers;
  }

  logout(): void {
    let icon_warning = localStorage.getItem('Icon_Warning.svg');
    localStorage.clear();
    icon_warning && localStorage.setItem('Icon_Warning.svg', icon_warning);
    this.userSubject.next(null);
  }

  private checkSession(): void {
    const user = JSON.parse(localStorage.getItem('user')!) || null;

    if (user) {
      this.userSubject.next(user);
    }
  }

  private saveLocalStorage(user: Flume): void {
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
  }
}
