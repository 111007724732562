import { Component, Input } from '@angular/core';

@Component({
  selector: 'agenx-totals',
  standalone: true,
  imports: [],
  templateUrl: './totals.component.html',
  styleUrl: './totals.component.scss'
})
export class AgenxTotalsComponent {

  /**
   * Total gauged string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalGauged: string = '0 m³ / 0 kgrs';
  /**
   * Total gauged subtitle months
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalGaugedSub: string = '';

  /**
   * Total month string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalMonth: string = '0 m³ / 0 kgrs';
  /**
   * Total month subtitle months
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalMonthSub: string = '';

  /**
   * Total day string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalDay: string = '0 m³ / 0 kgrs';
  /**
   * Total day subtitle months
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() totalDaySub: string = '';

  /**
   * today date
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() todayDate: string = '';

  /**
   * Total ordynary string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() ordynary: string = '0 m³ / 0 kgrs';
  /**
   * Total extraordynary string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() extraOrdynary: string = '0 m³ / 0 kgrs';
  /**
   * Total permament string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() permanent: string = '0 m³ / 0 kgrs';
  /**
   * Total ordynary string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() usable: string = '0 m³ / 0 kgrs';
  /**
   * Total extraordynary string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() unusable: string = '0 m³ / 0 kgrs';
  /**
   * Total permament string
   *
   * @type {string}
   * @memberof AgenxTotalsComponent
   */
  @Input() organic: string = '0 m³ / 0 kgrs';
}
