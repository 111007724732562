<div class="agenx-card-sub" [ngClass]="{'agenx-card-sub--orange': typeCard === 'orange'}">
  @if (cardSubButtonType === 'twoButton') {
  <h2 class="agenx-card-sub__h2"> Cantidad </h2>
  <h3 class="agenx-card-sub__h3"> {{ quantity }} </h3>
  }
  <ul class="agenx-card-sub__items">
    @for (content of card_info.valuesContent; track content.title) {
    <li class="agenx-card-sub__item">
      <p class="agenx-card-sub__item-text"> {{ content.title }} </p>
      <p class="agenx-card-sub__item-text"> {{ content.value ? (content.value | limitChars: 35) : ''}} </p>
    </li>
    } @empty {
    <p class="agenx-card-sub__item-empty"> ---------- </p>
    }
  </ul>
  @if (alertCard?.alertText) {
  <div class="agenx-card-sub__alert">
    <agenx-alert [alertType]="alertCard?.alertType!" [alertText]="alertCard?.alertText!"></agenx-alert>
  </div>
  }
  <footer class="agenx-card-sub__footer">
    @if (buttonCardText) {
      @switch (cardSubButtonType) {
      @case ('oneButton') {
      <div class="agenx-card-sub__footer-button">
        <agenx-button (click)="clickOnCardsub('Create')" [buttonText]="buttonCardText" [disabled]="alertCard ? true : false"></agenx-button>
      </div>
      }
      @case ('threeButton') {
      <div class="agenx-card-sub__footer-button">
        <agenx-circle-button (click)="clickOnCardsub('Create')"
          [buttonCircleSrc]="icon_create_src"
          [buttonCircleTitle]="'Crear aforo'"></agenx-circle-button>
        <agenx-circle-button (click)="clickOnCardsub('Download')"
          [buttonCircleSrc]="icon_download_src"
          [buttonCircleTitle]="'Descargar'"></agenx-circle-button>
        <agenx-circle-button (click)="clickOnCardsub('Resend')"
          [buttonCircleSrc]="icon_resend_src"
          [buttonCircleTitle]="'Reenviar'"></agenx-circle-button>
      </div>
      }
      @case ('twoButton') {
      <div class="agenx-card-sub__footer-button">
        <agenx-circle-button [typeButton]="'red'" (click)="clickOnCardsub('Delete')"
          [buttonCircleSrc]="icon_delete_src"
          [buttonCircleTitle]="'delete'"></agenx-circle-button>
        <agenx-circle-button (click)="clickOnCardsub('Edit')"
          [buttonCircleSrc]="icon_edit_src"
          [buttonCircleTitle]="'Edit'"></agenx-circle-button>
      </div>
      }
      }
    }

    <span class="agenx-card-sub__footer-line"></span>
  </footer>
</div>
