import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscriber } from '../interfaces/person.interface';
import { environment } from '../../environments/environment';
import { CardContent, GaugingFull } from '../interfaces/gauging.interface';
import { AccountService } from './account.service';
import { Microroute } from '../interfaces/dashboard.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private url = `${environment.api_url}/aforo/app`;
  private subs_info: CardContent | null = null;

  constructor(private accountSvc: AccountService, private http: HttpClient) {}

  // cleanSubsInfo(): void {
  //   localStorage.removeItem('subs_info');
  // }

  // cleanSearch(): void {
  //   localStorage.removeItem('search');
  // }

  getMicroroutes(): Observable<Microroute[]> {
    let headers = this.accountSvc.headers;
    return this.http.get<Microroute[]>(`${this.url}/microroutes/`, { headers });
  }

  // getSearch(): string {
  //   return localStorage.getItem('search') || '';
  // }

  // loadNotGaugingSub(): Array<Subscriber> {
  //   return JSON.parse(localStorage.getItem('not_gauging')!) ?? [];
  // }

  // loadGaugingSub(): Array<GaugingFull> {
  //   return JSON.parse(localStorage.getItem('gauging')!) ?? [];
  // }

  // loadMicroSelected(): { value: string; label: string } | null {
  //   return JSON.parse(localStorage.getItem('microroute')!) ?? null;
  // }

  // saveGaugingSub(array: GaugingFull[]) {
  //   localStorage.setItem('gauging', JSON.stringify(array));
  // }

  // saveMicroSelected(micro: { value: string; label: string }) {
  //   localStorage.setItem('microroute', JSON.stringify(micro));
  // }

  // saveNotGaugingSub(array: Subscriber[]) {
  //   localStorage.setItem('not_gauging', JSON.stringify(array));
  // }

  // saveSearch(search: string) {
  //   localStorage.setItem('search', search);
  // }

}
