import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgenxCircleButtonComponent } from "../circle-button/circle-button.component";
import { OfflineService } from '../../../../services/offline.service';

@Component({
  selector: 'agenx-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  imports: [NgClass, AgenxCircleButtonComponent],
})
export class AgenxCardComponent implements OnInit {
  @Input() title: string = 'Card title';
  @Input() borderTopLess: boolean = false;
  @Input() borderBottomLess: boolean = false;
  @Input() button: string | null = null;
  @Input() line: boolean = true;
  @Output() buttonClick = new EventEmitter<boolean>();

  currentClasses: Record<string, boolean> = {};
  icon_src = '';

  constructor(public offlineSrv: OfflineService) {}

  /**
   * Function to set the current classes of the card
   *
   * @memberof AgenxCardComponent
   */
  setCurrentClasses() {
    this.currentClasses = {
      'agenx-card--less-top': this.borderTopLess,
      'agenx-card--less-bottom': this.borderBottomLess,
    };
  }

  ngOnInit(): void {
    this.icon_src = this.offlineSrv.loadAssetInLocal('Icon_comeback2.svg');
    this.setCurrentClasses();
  }

  onButtonClick(): void {
    this.buttonClick.emit(true);
  }
}
