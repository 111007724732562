import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AgenxAuthComponent } from '../../ds/templates/auth/auth.component';

import { AccountService } from '../../../services/account.service';
import { ToastService } from '../../../services/toast.service';
import { OfflineService } from '../../../services/offline.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [AgenxAuthComponent, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private subscription: Subscription = new Subscription();

  error: string = '';
  userAuth: FormControl = new FormControl('', Validators.required);
  passwordAuth: FormControl = new FormControl('', Validators.required);
  state_button: 'loading' | 'normal' = 'normal';
  no_internet = false;

  /**
   * Handles the authentication process.
   * @param {any} $event - The authentication event.
   */
  authentication($event: any) {
    this.state_button = 'loading';
    if (this.no_internet) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.subscription.add(
        this.accountSrv.login($event).subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard');
            this.state_button = 'normal';
          },
          error: (error: { error: { detailed: string } }) => {
            this.state_button = 'normal';
            let message = error.error.hasOwnProperty('detailed')
              ? error.error.detailed
              : 'No se pudo iniciar sesión.';
            !navigator.onLine &&
              (message += ' No cuenta con una conexión estable a internet.');
            this.toastSrv.error(message);
          },
        })
      );
    }
  }

  constructor(
    private accountSrv: AccountService,
    private router: Router,
    private toastSrv: ToastService,
    private offlineSrv: OfflineService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.offlineSrv.onlineStatus$.subscribe((isOnline: boolean) => {
      this.no_internet = !isOnline;
      if (!this.no_internet) {
        this.offlineSrv.saveAssetsInLocal();
      }
    });
  }
}
